.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.wrapper .closeIcon {
  position: absolute;
  top: -0.34rem;
  right: 0.34rem;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrapper .title {
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.28rem;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFF4E7 0%, #FFE3BE 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin: 0 auto;
  max-width: 3.28rem;
}

.wrapper .contentWrapper {
  width: 3.28rem;
  height: 3.35rem;
  background: url('./resources/unpaid_cart.webp') no-repeat center center;
  background-size: 100% 100%;
  position: relative;
  margin: 0 auto;
}

.wrapper .contentWrapper .subtitle {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0.3rem;
  color: #AE5600;
  font-size: 0.12rem;
  line-height: 0.14rem;
  text-align: center;
}

.wrapper .contentWrapper .couponWrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0.6rem;
  text-align: center;
}

.wrapper .contentWrapper .couponWrapper .discount {
  font-size: 0.9rem;
  line-height: 1rem;
  color: #FF1F1F;
  font-weight: bold;
}

.wrapper .contentWrapper .couponWrapper .off {
  font-size: 0.24rem;
  color: #FF1F1F;
  font-weight: bold;
}

.wrapper.us .contentWrapper .couponWrapper .off {
  font-size: 0.5rem;
}

.wrapper .contentWrapper .couponWrapper .countdownWrapper {
  color: #FF1F1F;
  margin-top: 0.16rem;
  font-size: 0.12rem;
  line-height: 0.18rem;
}

.wrapper .contentWrapper .couponWrapper .countdownClassName {
  margin-left: 0.06rem;
}

.wrapper .couponWrapper .cardClassName {
  background-color: var(--neutral900);
  color: #FFEACD;
  border-radius: 0.04rem;
  height: 0.18rem;
  width: 0.18rem;
  font-size: 0.1rem;
  padding: 0.01rem;
  margin-right: 0.02rem;
}

.wrapper .couponWrapper .separatorClassName {
  color: var(--neutral900);
  font-size: 0.14rem;
}

.continue {
  width: 3.15rem;
  height: 0.5rem;
  border-bottom: 0.01rem solid #FF4E4E;
  background: #FF191E;
  box-shadow: 0 0.05rem 0 0 #E01216;
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.5rem;
  text-align: center;
  color: white;
  margin: 0 auto;
  margin-top: 0.17rem;
  border-radius: 0.5rem;
}

.closeBtnClassName {
  width: 0.32rem;
  height: 0.32rem;
  top: 0;
  right: 0;
  padding: 0.1rem;
  background-size: 0.12rem;
  background-position: center;
}

.wrapper .contentWrapper .cartItems {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0.15rem 0;
}

.cartItem {
  width: 0.6rem;
  height: 0.6rem;
  border: 0.01rem solid white;
  border-radius: 0.04rem;
  position: relative;
  overflow: hidden;
}

.cartItem img {
  border-radius: 0;
}

.cartItem:not(:last-child) {
  margin-right: 0.1rem;
}

.wrapper .contentWrapper .cartItem .mask {
  width: 100%;
  height: 0.15rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0, 0.5);
  color: white;
  text-align: center;
  font-size: 0.1rem;
  line-height: 0.15rem;
}

.wrapper .contentWrapper .cartItem .mask::after {
  content: '';
  width: 0.14rem;
  height: 0.14rem;
  background: url('https://img.flamingo.shop/p/show/81e2c1e86b13440a87bb70fa44e84998.png') no-repeat center center;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.wrapper.us .contentWrapper .cartItem .mask::after {
  display: none;
}